import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FeedbackReason, useFeedback } from "../../../state/feedback";
import { useAppToast } from "../../../hooks/useAppToast";
import { CreateRunFeedbackRequest_Rating } from "../../../gen-ts/ai/assistants/v0/assistant_pb";




interface MessageFeedbackDialogProps {
  runId: string;
  isOpen: boolean;
  onClose: () => void;
}
const MessageFeedbackDialog: React.FC<MessageFeedbackDialogProps> = ({ runId, isOpen, onClose }) => {
  const [ feedbackReason, setFeedbackReason ] = useState<FeedbackReason>(FeedbackReason.wrong_info);
  const [ otherReason, setOtherReason ] = useState<string>('');
  const toast = useAppToast();

  const feedback = useFeedback();


  const closeDialog = () => {
    setOtherReason('');
    setFeedbackReason(FeedbackReason.wrong_info);
    onClose();
  }

  const submitFeedback = () => {
    feedback.submitThreadRunFeedback({
      runId,
      rating: CreateRunFeedbackRequest_Rating.NEGATIVE,
      reason: feedbackReason,
      comment: feedbackReason === FeedbackReason.other ? otherReason : undefined,
    });
    closeDialog();
    toast({
      message: 'Feedback submitted.',
      variant: 'success',
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>We’re sorry to hear that.</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack pb={4} align="start">
            <Text>Please let us know what was unsatisfactory.</Text>
            <RadioGroup value={feedbackReason} onChange={(value) => setFeedbackReason(value as FeedbackReason)}>
              <Stack direction='column'>
                <Radio value={FeedbackReason.wrong_info}>Wrong information</Radio>
                <Radio value={FeedbackReason.too_verbose}>Too verbose</Radio>
                <Radio value={FeedbackReason.insufficient_info}>Insufficient information</Radio>
                <Radio value={FeedbackReason.other}>Other</Radio>
              </Stack>
            </RadioGroup>
            {
              feedbackReason === FeedbackReason.other && (
                <Textarea
                  rows={2}
                  placeholder="Please specify..."
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
              )
            }

          </VStack>
          <HStack justify="end" sx={{ my: 2 }}>
            <Button onClick={closeDialog}>
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              color="#000000"
              onClick={submitFeedback}
              isDisabled={feedbackReason === FeedbackReason.other && otherReason.trim() === ''}
            >
              Submit
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}


export default MessageFeedbackDialog;




