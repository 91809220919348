import {
  Flex,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import { useChats } from "../../../state/chats";
import { useEffect, useMemo } from "react";
import { MdLanguage } from "react-icons/md";
import { ExternalLinkIcon } from "@chakra-ui/icons";


interface MessageSourcesProps {
  runId?: string;
  filters?: string[];
  isOpen: boolean;
  onClose: () => void;
}
const MessageSourcesDialog: React.FC<MessageSourcesProps> = ({ runId, filters, isOpen, onClose }) => {
  const chats = useChats();

  useEffect(() => {
    if (isOpen && runId) {
      chats.loadRunById(runId);
    }

    //eslint-disable-next-line
  }, [ isOpen ]);

  const isLoading = chats.runLoading[ runId || '' ] || false;
  const run = chats.runs[ runId || '' ] || null;

  const iconBgColor = useColorModeValue("gray.100", "gray.800");

  const list = useMemo(() => {
    const documents = (run?.contextDocuments || []).filter(doc => {
      if (doc.url.startsWith('"https://storage.google')) {
        return false;
      }
      if (doc.url.includes('slack.com/archives')) {
        return false;
      }

      return true;
    });
    
    if (!filters) {
      return documents;
    }

    return documents.filter(doc => {
      return filters.includes(doc.filename);
    });

  }, [ filters, run?.contextDocuments ]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sources</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack pb={4}>
            {
              isLoading && (
                <>
                  <Skeleton width="100%" height="10px" />
                  <Skeleton width="100%" height="10px" />
                  <Skeleton width="100%" height="10px" />
                  <Skeleton width="100%" height="10px" />
                </>
              )
            }
            {
              !isLoading && list.length === 0 && (
                <Text>No sources for this message.</Text>
              )
            }
            {
              !isLoading && list.map((doc, index) => (
                <HStack width="100%" key={index}>
                  <Flex
                    px={3}
                    py={2}
                    borderRadius={5}
                    bgColor={iconBgColor}
                    height="100%"
                  >
                    <Icon
                      color="gray.500"
                      as={MdLanguage}
                    />
                  </Flex>
                  <Link
                    href={doc.url}
                    isExternal
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {doc.displayName ? doc.displayName : doc.filename} {doc.trailer ? `(${doc.trailer})` : ''}
                  </Link>
                  <ExternalLinkIcon mx="2px" mb="3px" />
                </HStack>
              ))
            }
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}


export default MessageSourcesDialog;