import { getSkin } from "../skins";

class LocalStorage {

  public static defaultAssistantId = 'default';

  public static getSelectedAssistantId(): string {
    return getSkin().assistantId ||
      window.localStorage.getItem('selectedAssistantId') || LocalStorage.defaultAssistantId;
  }

  public static setSelectedAssistantId(id: string): void {
    return window.localStorage.setItem('selectedAssistantId', id);
  }

  public static getLeftPanelWidth(): string {
    return window.localStorage.getItem('leftPanelWidth') || '250';
  }

  public static setLeftPanelWidth(width: string): void {
    return window.localStorage.setItem('leftPanelWidth', width);
  }


  public static setIsDebugMode(isDebug: boolean): void {
    return window.localStorage.setItem('isDebug', isDebug ? 'true' : 'false');
  }

  public static getIsDebugMode(): boolean {
    return window.localStorage.getItem('isDebug') === 'true';
  }
}

export default LocalStorage;