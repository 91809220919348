import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/Login';
import { AppFrame } from './components/AppFrame/AppFrame';
import ChatPage from './pages/Chat';
import HomePage from './pages/Home';
import AssistantsPage from './pages/Assistants';
import EditAssistantPage from './pages/EditAssistant';
import HelpPage from './pages/Help';
import ManageChannelPage from './pages/ManageChannel/ManageChannel';
import ShareChat from './pages/ShareChat';
import ShareChatContinue from './pages/ShareChatContinue';
import { getSkin } from './skins';




export default function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route element={<AppFrame />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/chat/:threadId" element={<ChatPage />} />
        <Route path="/share/:threadId" element={<ShareChat />} />
        <Route path="/share/:threadId/continue" element={<ShareChatContinue />} />
        {
          !getSkin().assistantId && (
            <>
              <Route path="/assistants" element={<AssistantsPage />} />
              <Route path="/assistants/:assistantId" element={<EditAssistantPage />} />
              <Route path="/help" element={<HelpPage />} />
              <Route path="/channel" element={<ManageChannelPage />} />
            </>
          )
        }
      </Route>
    </Routes>
  );
}