import { extendTheme } from "@chakra-ui/react";
import { getSkin } from "./skins";


const theme = extendTheme({
  initialColorMode: 'dark',
  useSystemColorMode: false,
  colors: {
    primary: getSkin().primaryColor,
  }
});


export default theme;

