import { Box, Button, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Skeleton, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { MdInfoOutline, MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import { Assistant } from "../../gen-ts/ai/assistants/v0/assistant_pb";
import { useAssistants } from "../../state/assistants";
import { getSkin } from "../../skins";


interface Props {
  isLoading: boolean;
  isSmall: boolean;
  assistant?: Assistant;
}

const ExistingChatAssistantButton: React.FC<Props> = ({ isSmall, isLoading, assistant }) => {
  const assistantsState = useAssistants();

  let name = assistant?.displayName ?? '';
  if (isSmall && name.length > 20) {
    name = name.slice(0, 20) + '...';
  }

  return (
    <>
      <VStack>
        <Skeleton width="200px" fitContent isLoaded={!isLoading}>
          {
            !getSkin().assistantId ? (
              <Button
                variant="ghost"
                rightIcon={<MdKeyboardArrowDown />}
                size={
                  isSmall ? 'md' : 'lg'
                }
                as={Link}
                to="/assistants">
                {name}
              </Button>
            ) : (
              <Box>
                <Text fontSize="lg" fontWeight="bold" sx={{
                  whiteSpace: 'nowrap',
                }}>
                  {name}
                </Text>
              </Box>
            )
          }

        </Skeleton>
      </VStack>
      {
        !assistantsState.loadingById &&
        <Popover placement="bottom-end">
          <PopoverTrigger>
            <IconButton
              icon={<MdInfoOutline />}
              aria-label="Assistant Info"
              borderRadius="50px"
              variant="ghost"
              fontSize="25px"
              size={isSmall ? 'md' : 'lg'}
            />
          </PopoverTrigger>
          <PopoverContent boxShadow="lg">
            <PopoverArrow />
            <PopoverBody>{assistant?.description}</PopoverBody>
          </PopoverContent>
        </Popover>
      }
    </>
  );
};

export default ExistingChatAssistantButton;