import React, { ReactNode, useEffect } from "react";
import { Message } from "../../../gen-ts/ai/assistants/v0/assistant_pb";
import { useChats } from "../../../state/chats";
import { Box, HStack, Icon, Image, Link, Skeleton, useColorModeValue, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { MdAttachment, MdImage } from "react-icons/md";

const MessageFiles: React.FC<{ message: Message }> = ({ message }) => {

  var chats = useChats();
  useEffect(() => {
    for (let i = 0; i < message.content.length; i++) {
      const content = message.content[ i ];

      if (content.content.case !== 'file') {
        continue;
      }

      chats.loadFile(content.content.value.source.value!);
    }
    //eslint-disable-next-line
  }, []);


  const files: ReactNode[] = [];
  const images: ReactNode[] = [];
  const fileBgColor = useColorModeValue('gray.50', 'gray.700');
  const fileBorderColor = useColorModeValue('gray.300', 'gray.600');

  for (let i = 0; i < message.content.length; i++) {
    const content = message.content[ i ];

    if (content.content.case !== 'file') {
      continue;
    }

    const ref = content.content.value.source.value;
    const fileInfo = chats.files[ ref ?? '' ];
    const fileLoading = chats.fileLoading[ ref ?? '' ];


    if (ref && fileInfo && !fileLoading) {
      if (fileInfo.contentType.startsWith('image1111/')) {
        images.push(
          <Link target="_blank" href={fileInfo.url}>
            <Box width="100px" height="100px">
              <Image
                src={fileInfo.url}
                alt={fileInfo.fileName}
                width="100px"
                height="100px"
                fit="cover"
                borderRadius={10}
              />
            </Box>
          </Link>
        );
      }
      else {
        files.push(
          <HStack
            bgColor={fileBgColor}
            borderColor={fileBorderColor}
            borderWidth="1px"
            px={2}
            py={1}
            borderRadius={10}
            maxWidth="500px"
            fontSize="14px"
          >
            <Icon
              as={
                fileInfo.contentType.startsWith('image') ? MdImage : MdAttachment
              }
            />
            <Link
              isTruncated
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              target="_blank"
              title={fileInfo.fileName}
              href={fileInfo.url}
            >
              {fileInfo.fileName}
            </Link>
          </HStack>
        );
      }
    }
    else {
      files.push(
        <Skeleton width="200px" height="31px" />
      )
    }
  }



  return (
    <>
      <Wrap justify="end" width="100%">
        {
          images.map((child, index) => (
            <WrapItem key={index}>
              {child}
            </WrapItem>
          ))
        }
      </Wrap>
      <VStack width="100%" align="end" spacing="3px">
        {
          files.map((child, index) => (
            <Box key={index}>
              {child}
            </Box>
          ))
        }
      </VStack>
    </>
  );
}

export default MessageFiles;