import { create } from "zustand"
import { getApiClient } from "../api";
import { CreateRunFeedbackRequest, CreateRunFeedbackRequest_Rating } from "../gen-ts/ai/assistants/v0/assistant_pb";


export enum FeedbackReason {
  wrong_info = 'wrong_info',
  too_verbose= 'too_verbose',
  insufficient_info = 'insufficient_info',
  other = 'other',
};

interface SubmitFeedbackArgs {
  runId: string;
  rating: CreateRunFeedbackRequest_Rating;
  reason?: FeedbackReason;
  comment?: string;
}

interface FeedbackState {
  threadRunFeedbacks: Record<string, SubmitFeedbackArgs>;
  submitThreadRunFeedback: (args: SubmitFeedbackArgs) => void;
}


export const useFeedback = create<FeedbackState>((set, get) => ({
  threadRunFeedbacks: {},
  submitThreadRunFeedback: async (args: SubmitFeedbackArgs) => {
    const { client, headers } = getApiClient();
    const req = new CreateRunFeedbackRequest({
      runId: args.runId,
      rating: args.rating,
      reason: args.reason,
      comment: args.comment,
    });
    client.createRunFeedback(req, { headers });
    set({ threadRunFeedbacks: { ...get().threadRunFeedbacks, [ args.runId ]: args } });
  },

}));


// Options: []*slack.OptionBlockObject{
//   {
//     Text: &slack.TextBlockObject{
//       Type: slack.PlainTextType,
//       Text: "Wrong information",
//     },
//     Value: "wrong_info",
//   },
//   {
//     Text: &slack.TextBlockObject{
//       Type: slack.PlainTextType,
//       Text: "Too verbose",
//     },
//     Value: "too_verbose",
//   },
//   {
//     Text: &slack.TextBlockObject{
//       Type: slack.PlainTextType,
//       Text: "Insufficient information",
//     },
//     Value: "insufficient_info",
//   },
//   {
//     Text: &slack.TextBlockObject{
//       Type: slack.PlainTextType,
//       Text: "Other",
//     },
//     Value: "other",
//   },