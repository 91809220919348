import { Avatar, Button, HStack, Icon, useClipboard, VStack } from "@chakra-ui/react";
import React from "react";
import { useChats } from "../../../state/chats";
import { useDebugDialog } from "../../DebugDialogProvider";
import { useAssistants } from "../../../state/assistants";
import { getSkin } from "../../../skins";
import MessageContent from "./MessageContent";
import { MdContentCopy, MdLanguage } from "react-icons/md";
import { CgDebug } from "react-icons/cg";
import { Message } from "../../../gen-ts/ai/assistants/v0/assistant_pb";
import { customRenderers } from "./CustomRenderers/customRenderers";
import { useAppToast } from "../../../hooks/useAppToast";
import LocalStorage from "../../../services/localStorage";

interface AssistantMessageProps {
  message: Message;
  streamRunning: boolean;
  onShowSources: (runId: string, filters?: string[]) => void;
  onFeedback: (runId: string) => void;
  assistantId: string;
}

const getMessageCopyText = (message: Message) => {
  return message.content.map(c => c.content.value).join('\n');
}

const AssistantMessage: React.FC<AssistantMessageProps> = ({ message, streamRunning, onShowSources, assistantId, onFeedback }) => {
  const { onCopy } = useClipboard(getMessageCopyText(message));
  const toast = useAppToast();
  const chatsState = useChats();
  const debugDialog = useDebugDialog();
  const assistantsState = useAssistants();
  const assistant = assistantsState.assistants[ assistantId ];

  const handleOnCopy = () => {
    onCopy();
    toast({
      message: 'Copied to clipboard.',
      variant: 'success',
    });
  }

  const run = chatsState.runs[ message.runId ];

  const CustomRenderer = customRenderers[ assistantId ];


  return (
    <HStack
      justify="start"
      alignSelf="start"
      // maxWidth={600}
      width="100%"
      align="start"
    >
      <Avatar name='System Message' src={getSkin().logo} size="sm" />
      <VStack align="start">
        {
          CustomRenderer ? (
            <CustomRenderer
              message={message}
              onFeedback={onFeedback}
              onShowSources={onShowSources}
            />
          ) : (
            <MessageContent message={message} />
          )
        }

        <HStack justify="start" spacing={3} visibility={streamRunning ? 'hidden' : 'visible'}>
          {
            !getSkin().assistantId && (
              <Button onClick={handleOnCopy} size="xs" variant="ghost" leftIcon={<Icon as={MdContentCopy} fontSize="13px" />}>
                Copy
              </Button>
            )
          }


          {
            !assistant?.citationsEnabled && (
              <Button
                size="xs"
                variant="ghost"
                leftIcon={<Icon as={MdLanguage}
                  fontSize="13px" />}
                onClick={() => {
                  onShowSources(message.runId);
                }}
              >
                Sources {
                  run && (
                    (`(${run.contextDocuments.length})`)
                  )
                }
              </Button>
            )
          }

          {
            (!getSkin().assistantId || LocalStorage.getIsDebugMode()) && (
              <Button
                isLoading={chatsState.runLoading[ message.runId ]}
                onClick={async () => {
                  const run = await chatsState.loadRunById(message.runId);
                  const runTime = run?.endTime && run?.startTime ? run.endTime.seconds - run.startTime.seconds : 0;
                  debugDialog.openDialog({
                    'Run ID': message.runId,
                    "Run Duration": `${runTime.toString()} seconds`, 
                  });
                }}
                size="xs"
                variant="ghost"
                leftIcon={<Icon as={CgDebug} fontSize="13px" />}
              >
                Debug
              </Button>
            )
          }

        </HStack>
      </VStack>
    </HStack>
  );
}


export default AssistantMessage;