import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Hide,
  IconButton,
  Skeleton,
  Text,
  Textarea,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import {
  MdAddCircleOutline,
  MdArrowCircleUp,
  MdMic,
  MdStopCircle
} from "react-icons/md";

import Lottie from 'react-lottie';
import * as animationData from './recording-animation.json'
import { useFileUpload } from "./FileUpload";
import InputFiles from "./InputFiles";
import { OnSendMessageArgs } from "./ChatUI";
import { getSkin } from "../../skins";




const InputHeightContext = React.createContext({
  height: 50,
  setHeight: (height: number) => { },
});

export const InputHeightProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [ height, setHeight ] = useState(50);

  return (
    <InputHeightContext.Provider value={{ height, setHeight }}>
      {children}
    </InputHeightContext.Provider>
  );
}

export const useInputHeight = () => {
  return React.useContext(InputHeightContext);
}


interface ChatInputProps {
  onSend: (args: OnSendMessageArgs) => void;
  onStop: () => void;
  status: 'idle' | 'sending';
  loading: boolean;
  streamStartPending?: boolean;
  newChatPending?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSend, onStop, status, loading, streamStartPending, newChatPending }) => {
  const toast = useToast();

  const [ value, setValue ] = useState('');

  const inputHeight = useInputHeight();
  

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [ isSpeechApiSupported, setIsSpeechApiSupported ] = useState(false);
  const [ isListening, setIsListening ] = useState(false);
  const recognitionRef = useRef<SpeechRecognition | null>(null);

  const fileUpload = useFileUpload();

  useEffect(() => {
    adjustHeight();
    // eslint-disable-next-line
  }, [ value ]);

  useEffect(() => {
    const isSupported = !!((window as any).SpeechRecognition || (window as any).webkitSpeechRecognition);
    setIsSpeechApiSupported(isSupported);
    // eslint-disable-next-line
  }, []);


  // useEffect(() => {
  //   adjustHeight();

  // }, [fileUpload.files]); 


  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  }

  const sendMessage = () => {
    if (value.trim().length > 0) {
      const files = [...fileUpload.files];
      fileUpload.deleteAllFiles();      
      onSend({
        message: value,
        files: files,
      });
      setValue('');
    }
    inputRef.current?.focus();
  }


  const hanldleClick = () => {
    if (status === 'sending') {
      onStop();
    }
    else if (isSpeechApiSupported && value.length === 0 && !isListening) {
      startSpeechRecognition();
    }
    else if (isSpeechApiSupported && isListening) {
      stopSpeechRecognition();
    }
    else {
      sendMessage();
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    sendMessage();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // Prevents default Enter key behavior (new line)
      event.preventDefault();
      const form = event.currentTarget.form;
      if (form) {
        form.requestSubmit();
      }
    }
  };

  const adjustHeight = () => {
    const textarea = inputRef.current;
    if (textarea) {
      if (textarea.scrollHeight >= 348) {
        return;
      }

      // if (value.length === 0) {
      //   textHeight = 38;
      // }

      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
      inputHeight.setHeight(textarea.scrollHeight + 12);
    }
  }

  const stopSpeechRecognition = () => {
    recognitionRef.current?.stop();
    setIsListening(false);
  }

  const startSpeechRecognition = async () => {
    navigator.permissions.query({ name: 'microphone' as any }).then((result) => {
      if (result.state === 'denied') {
        toast({
          title: 'Microphone permission denied.',
          description: 'Please allow microphone access to use speech recognition',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        return;
      }

      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = 'en-US';
      // recognition.continuous = true;
      recognition.interimResults = true;

      recognitionRef.current = recognition;

      recognitionRef.current.start();

      recognition.onresult = (event: SpeechRecognitionEvent) => {
        let interimTranscript = '';
        let finalTranscript = '';

        // Loop through results and append final and interim results
        for (let i = 0; i < event.results.length; i++) {
          const result = event.results[ i ];
          if (result.isFinal) {
            finalTranscript += result[ 0 ].transcript;
          } else {
            interimTranscript += result[ 0 ].transcript;
          }
        }

        // Set the transcript (interim or final)
        setValue(finalTranscript || interimTranscript);
      };

      recognition.onerror = (event: any) => {
        setIsListening(false);
        console.error('Speech recognition error:', event.error);
      };

      recognition.onend = () => {
        setIsListening(false);
        console.log('Speech recognition ended');
      };




      recognition.onstart = () => {
        setIsListening(true);
        console.log('Speech recognition started');
      }

    });

  }

  const iconColor = useColorModeValue('gray.600', 'gray.500');

  let rightIcon = <MdArrowCircleUp />;
  if (status === 'sending') {
    rightIcon = <MdStopCircle />;
  }
  else if (isSpeechApiSupported && isListening) {
    rightIcon = (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        height={50}
        width={50} />
    );
  }
  else if (isSpeechApiSupported && !isListening && value.length === 0) {
    rightIcon = <MdMic />;
  }

  const maxWidth = 1000;

  const uploadPending = fileUpload.files.some(f => f.status === 'uploading');


  return (
    <VStack spacing={0} width="100%" maxWidth={`${maxWidth}px`}>
      <Skeleton fitContent isLoaded={!loading} width="100%" maxWidth={`${maxWidth}px`} borderRadius={25}>
        <form

          onSubmit={handleSubmit}
          style={{
            width: "100%",
            maxWidth: `${maxWidth}px`,
          }}
        >
          <Flex
            direction="column"
            width="100%"
            maxWidth={`${maxWidth}px`}
            height={`${fileUpload.files.length > 0 ? inputHeight.height + fileUpload.renderContainerHeight : inputHeight.height}px`}
            align="start"
            borderColor="gray.500"
            borderWidth="1px"
            borderRadius="25px"
            overflow="hidden"
          >
            <InputFiles />
            <HStack width="100%" spacing={0} height="100%">
              <IconButton
                icon={<MdAddCircleOutline />}
                aria-label="attach file"
                variant="ghost"
                borderRadius="999px"
                size="lg"
                fontSize="25px"
                color={iconColor}
                alignSelf="end"
                onClick={fileUpload.open}
              />
              <Box flexGrow={1} px={1}>
                <Textarea
                  autoFocus
                  ref={inputRef}
                  value={value}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                  variant='unstyled'
                  placeholder='Start typing...'
                  rows={1}
                  resize="none"
                  style={{
                    height: '38px',
                  }}
                />
              </Box>

              {
                !streamStartPending && !newChatPending && (
                  <IconButton
                    icon={rightIcon}
                    aria-label="send"
                    variant="ghost"
                    borderRadius="999px"
                    size="lg"
                    fontSize="25px"
                    color={isSpeechApiSupported && isListening ? 'red.500' : iconColor}
                    alignSelf="end"
                    // isDisabled={value.trim().length === 0 && !isSpeechApiSupported}
                    type="button"
                    onClick={hanldleClick}
                    isDisabled={value !== '' && uploadPending && status === 'idle'}
                  />
                )
              }
            </HStack>
          </Flex>

        </form>
      </Skeleton>
      <Hide below="md">
      <Text fontSize={12} mt={1}>
        {getSkin().appTitle} is in a beta stage and can make mistakes. Consider verifying important information.
      </Text>
      <Text fontSize={12}>
        Responses are generated based on the input provided and may not always reflect accurate or up-to-date information.
      </Text>
      </Hide>
    </VStack>

  );
}

export default ChatInput;