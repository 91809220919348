import {
  VStack,
} from "@chakra-ui/react";
import { forwardRef, useRef, useState } from "react";
import { ChatInfo } from "../../../types";
import { Role } from "../../../gen-ts/ai/assistants/v0/assistant_pb";

import MessageSourcesDialog from "./MessageSourcesDialog";
import UserMessage from "./UserMessage";
import AssistantMessage from "./AssistantMessage";
import MessageFeedbackDialog from "./MessageFeedbackDialog";


interface CurrMessageSources {
  runId: string;
  filters?: string[];
}

interface ChatMessagesProps {
  chat: ChatInfo | null;
  onUserScrollUpdate: (isUserScrolled: boolean) => void;
}

const ChatMessages = forwardRef<HTMLDivElement, ChatMessagesProps>(({ chat, onUserScrollUpdate }, ref) => {

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [ currMessageSources, setCurrMessageSources ] = useState<CurrMessageSources | null>(null);
  const [ currMessageFeedback, setCurrMessageFeedback ] = useState<string | null>(null);

  const showSources = (runId: string, filters?: string[]) => {
    setCurrMessageSources({
      runId,
      filters,
    });
  }

  const hideSources = () => {
    setCurrMessageSources(null);
  }

  const showFeedback = (runId: string) => {
    setCurrMessageFeedback(runId);
  }

  const hideFeedback = () => {
    setCurrMessageFeedback(null);
  }

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
      // console.log(scrollTop + clientHeight, scrollHeight);
      if (scrollHeight - (scrollTop + clientHeight) < 20) {
        onUserScrollUpdate(false);
      } else {
        onUserScrollUpdate(true);
      }
    }
  };

  return (
    <>
      <VStack height="100%" width="100%" overflow="scroll" onScroll={handleScroll} ref={containerRef}>
        <VStack maxWidth="1000px" width="100%" spacing={6}>
          {
            chat?.messages.map((message, index) => {


              if (message.role === Role.ASSISTANT) {
                const streaming = chat.streamingResponse && index === chat.messages.length - 1 ? true : false;
                return <AssistantMessage
                  key={index}
                  message={message as any}
                  streamRunning={streaming}
                  onShowSources={showSources}
                  onFeedback={showFeedback}
                  assistantId={chat.assistantId}
                />;
              }
              else {
                return <UserMessage key={index} message={message as any} />;
              }
            })
          }
        </VStack>
        <div ref={ref} />
      </VStack>
      <MessageSourcesDialog
        isOpen={Boolean(currMessageSources)}
        onClose={hideSources}
        runId={currMessageSources?.runId}
        filters={currMessageSources?.filters}
      />

      <MessageFeedbackDialog
        isOpen={Boolean(currMessageFeedback)}
        onClose={hideFeedback}
        runId={currMessageFeedback || ''}
      />
    </>
  );
});



export default ChatMessages;





