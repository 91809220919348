import { AppSkin } from "./types";

const skins: {[key: string]: AppSkin} = {
  default: {
    appTitle: 'R2G2',
    primaryColor: {
      50: '#dcfffa',
      100: '#b0fff1',
      200: '#81fde7',
      300: '#53fcde',
      400: '#2ffad4',
      500: '#20e1bb',
      600: '#11b091',
      700: '#027e68',
      800: '#004b3e',
      900: '#001b15',
    },
    logo: '/skin-assets/default/logo512.png',
  },
  apex: {
    appTitle: 'Apex Ascend',
    primaryColor: {
      50: '#00FFFF',
      100: '#00FFFF',
      200: '#00FFFF',
      300: '#00FFFF',
      400: '#00F9FF',
      500: '#00E2F9',
      600: '#00CBE0',
      700: '#00B5C7',
      800: '#008895',
      900: '#005A64'
    },
    logo: '/skin-assets/apex/logo512.png',
    
    // if defined the app willbe locked onlyl to this assistant
    assistantId: 'ASSISTANT_4635ZEG88M09MBVJW2X4N4Q4R1',
  }
}

export const getSkin = () => {
  const skin = process.env.REACT_APP_SKIN || 'default'
  return skins[skin];
}