import {
  Button,
  Collapse,
  Flex,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdAdd, MdDelete, MdKeyboardArrowDown, MdMoreHoriz } from "react-icons/md";
import { useAssistants } from "../../state/assistants";
import { Alias } from "../../gen-ts/slack/slack_config_pb";
import ToastMessage from "../../components/ToastMessage";
import AddAliasDialog from "./AddAliasDialog";

interface Props {
  originalChannelAliases: { [ key: string ]: Alias };
  aliases: { [ key: string ]: Alias };
  onAddAlias: (name: string, alias: Alias) => void;
  onDeleteAlias: (name: string) => void;
}

const ChannelAliases: React.FC<Props> = ({ aliases, onAddAlias, onDeleteAlias, originalChannelAliases }) => {
  const [ expanded, setExpanded ] = useState(false);
  const assistants = useAssistants();
  const toast = useToast();

  const [ addAliasDialogOpen, setAddAliasDialogOpen ] = useState(false);


  useEffect(() => {

    for (const alias in (aliases ?? {})) {
      const assistantId = aliases[ alias ].assistantId;
      assistants.loadById({
        assistantId,
        onError: () => {
          toast({
            position: 'top-right',
            render: (props) => {
              return (
                <ToastMessage
                  message={`Assistant: ${assistantId} not found.`}
                  status="error"
                  isCLosable={props.isClosable}
                  onClose={props.onClose}
                />
              );
            },
            duration: 2000,
            isClosable: true,
          });
        }
      });
    }

    //eslint-disable-next-line
  }, [ aliases ]);


  const aliaseslist = Object.keys(aliases ?? {});
  aliaseslist.sort();

  const tableBgColor = useColorModeValue('white', 'gray.800');
  const newAliasBgColor = useColorModeValue('primary.50', 'primary.800');

  return (
    <>
      <VStack
        bgColor={useColorModeValue('gray.100', 'gray.700')}
        borderRadius={10}
        p={4}
        width="100%"
      >
        <HStack width="100%" justify="space-between">
          <Text fontWeight="600">Channel Aliases</Text>
          <HStack>
            <Button
              size="sm"
              variant="ghost"
              rightIcon={
                <Icon
                  aria-label="expand"
                  transition='transform 0.2s'
                  transform={expanded ? 'rotate(180deg)' : ''}
                  as={
                    MdKeyboardArrowDown
                  }
                />
              }
              onClick={() => setExpanded(!expanded)}
            >
              How does it work
            </Button>
            <IconButton
              onClick={() => { setAddAliasDialogOpen(true); }}
              aria-label="Create assistant" icon={<MdAdd />}
              colorScheme="primary"
              color="black"
            />
          </HStack>
        </HStack>
        <Collapse in={expanded} animateOpacity startingHeight="1px">
          <HStack mt="1px" p={3} borderRadius={10} spacing={4} bgColor={useColorModeValue('gray.200', 'gray.800')}>
            <svg width="56" height="78" viewBox="0 0 56 78" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="56" height="78" rx="28" fill="#80FDE7" />
              <path d="M39 36H30L32 24L17 42H26L24 54L39 36Z" fill="#001A15" />
            </svg>
            <VStack align="start" spacing={0}>
              <Text fontWeight="600">Assistant Aliases</Text>
              <Text>
                Easily switch between different assistants within the same chat channel—no need to juggle multiple channels. Create as many aliases for this channel as you’d like.
              </Text>
            </VStack>
          </HStack>
        </Collapse>

        <AddAliasDialog
          isOpen={addAliasDialogOpen}
          onClose={() => setAddAliasDialogOpen(false)}
          onAddAlias={onAddAlias}
        />

        {
          aliaseslist.length === 0 && (
            <Flex justify="center" align="center" width="100%">
              <Text>No aliases</Text>
            </Flex>
          )
        }

        {
          aliaseslist.length > 0 && (
            <TableContainer bgColor={tableBgColor} borderRadius={10} width="100%" mt={4} whiteSpace="wrap">
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>Alias</Th>
                    <Th>Assistant</Th>
                    <Th >ID</Th>
                    <Th width="0px"></Th>
                    <Th width="0px"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    aliaseslist.map((aliasName) => {
                      const alias = aliases[ aliasName ];
                      if (!alias) {
                        return null;
                      }

                      const assistant = assistants.assistants[ alias.assistantId ];
                      const isNew = !originalChannelAliases[ aliasName ];
                      return (
                        <Tr key={aliasName} sx={{
                          backgroundColor: isNew ? newAliasBgColor : 'inherit',
                          transition: 'background-color 0.2s'
                        }}>
                          <Td>{aliasName}</Td>
                          <Td>{assistant?.displayName}</Td>
                          <Td>{assistant?.id}</Td>
                          <Td>
                            <Popover placement="top-end">
                              <PopoverTrigger>
                                <IconButton aria-label="description" variant="ghost" sx={{ borderRadius: 99 }}>
                                  <MdMoreHoriz />
                                </IconButton>
                              </PopoverTrigger>
                              <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>{assistant?.description}</PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </Td>
                          <Td>
                            <IconButton
                              icon={<MdDelete />}
                              aria-label="Delete"
                              variant="ghost"
                              onClick={() => {
                                onDeleteAlias(aliasName);
                              }}
                            />
                          </Td>
                        </Tr>
                      );
                    })
                  }
                </Tbody>
              </Table>
            </TableContainer>
          )
        }
      </VStack>
    </>
  );
};

export default ChannelAliases;