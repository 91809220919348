import React, { ReactNode } from "react";
import { Message, Role } from "../../../gen-ts/ai/assistants/v0/assistant_pb";
import MessageText from "./MessageText";
import { Text, VStack } from "@chakra-ui/react";

const MessageContent: React.FC<{ message: Message }> = ({ message }) => {

  const children: ReactNode[] = [];

  for (let i = 0; i < message.content.length; i++) {
    const content = message.content[ i ];

    if (content.content.case !== 'text') {
      continue;
    }

    if (content.content.case === 'text') {
      if (message.role === Role.ASSISTANT) {
        // console.log('markdown', content.content.value);
        // content.content.value = content.content.value
        //   .replace(/^\s{2}\* /gm, '\t\t* ')
        //   .replace(/^\s{2}- /gm, '\t\t- ')
        //   .replace(/^(- )/gm, '\t- ')
        //   .replace(/^(\* )/gm, '\t* ')
        //   ;
        // console.log('markdown', content.content.value);
        children.push(
          <MessageText key={i} text={content.content.value} />
        );
      }
      else {
        children.push(
          <VStack key={i} spacing={0} align="start">
            {
              content.content.value.split('\n').map((text, index) => (
                <Text key={index}>{text}</Text>
              ))
            }
          </VStack>
        );
      }
    }
    // else if (content.content.case === 'image') {
    //   if (content.content.value.source.case === 'url') {
    //     children.push(<Image key={i} src={content.content.value.source.value} width="300px" />);
    //   }
    //   else if (content.content.value.source.case === 'data') {
    //     const decoder = new TextDecoder('utf8');
    //     const b64encoded = btoa(decoder.decode(content.content.value.source.value.data));
    //     children.push(<Image key={i} src={b64encoded} width="300px" />);
    //   }
    // }
    // else {
    //   children.push(
    //     <Text key={`${i}-1`}>Unsupoported message type: {content.content.case}</Text>
    //   );
    // }

  }

  return (
    <VStack align="start">
      {children}
    </VStack>
  );
}


export default MessageContent;