import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Text,
  useClipboard,
  useColorModeValue,
  useToast,
  VStack
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import AccountWidget from "./AccountWidget";
import { useChats } from "../../state/chats";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdDelete, MdEdit, MdMoreHoriz } from "react-icons/md";
import { IoIosSend, IoIosAddCircle } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";

import { useDialog } from "../DialogProvider";
import LocalStorage from "../../services/localStorage";
import ToastMessage from "../ToastMessage";
import { getSkin } from "../../skins";



const LeftPanel: React.FC<{ isInDrawer: boolean }> = ({ isInDrawer }) => {
  const chatsState = useChats();
  const observer = useRef<IntersectionObserver | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const [ panelWidth, setPanelWidth ] = useState(LocalStorage.getLeftPanelWidth());
  const resizing = useRef(false);

  const [ renameDialogOpen, setRenameDialogOpen ] = useState(false);
  const [ chatName, setChatName ] = useState('');
  const renameInitialRef = React.useRef(null);
  const [ shareDialogThread, setShareDialogThread ] = useState<string | null>(null);

  useEffect(() => {
    chatsState.loadChats();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (resizing.current) {
        // Ensure minimum and maximum panel width
        const newWidth = Math.max(200, Math.min(window.innerWidth * 0.25, e.clientX));
        setPanelWidth(newWidth.toString());
        LocalStorage.setLeftPanelWidth(newWidth.toString());
      }
    };

    const handleMouseUp = () => {
      if (resizing.current) {
        resizing.current = false;
        document.body.style.userSelect = "";
      }
    };

    // Add event listeners to the window
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const handleMouseDown = () => {
    resizing.current = true;
    document.body.style.userSelect = "none";
  };



  useEffect(() => {
    console.log('setup intersection observer');
    if (sentinelRef.current) {
      observer.current = new IntersectionObserver(
        ([ entry ]) => {
          if (entry.isIntersecting) {
            chatsState.loadChats();
          }
        },
        {
          root: document.querySelector('#chat-list'),
          rootMargin: '0px',
          threshold: 1.0,
        }
      );

      observer.current.observe(sentinelRef.current);

      return () => {
        console.log('remove');
        if (observer.current) {
          observer.current.disconnect(); // Clean up the observer
        }
      };
    }

    //eslint-disable-next-line
  }, []);

  const params = useParams();
  const dialog = useDialog();

  const onDeleteChat = (threadId: string) => {
    dialog.confirm({
      title: 'Delete Chat',
      subtitle: 'Are you sure you want to delete this chat?',
      onConfirm: () => {
        navigate('/', { replace: true });
        chatsState.deleteChat(threadId);
      }
    });
  }

  const onEditChat = (threadId: string) => {
    const thread = chatsState.threads.find((t) => t.id === threadId);
    setChatName(thread?.description || '');
    setRenameDialogOpen(true);
  }

  const closeRenameDialog = () => {
    setRenameDialogOpen(false);
  }

  const renameChat = () => {
    setRenameDialogOpen(false);
    if (params.threadId) {
      chatsState.renameChat(params.threadId, chatName);
    }
  }

  const closeShareDialog = () => {
    setShareDialogThread(null);
  }

  const onShareChat = (threadId: string) => {
    setShareDialogThread(threadId);
  }

  // chatsState.loadingList = false;

  const skin = getSkin();

  return (
    <>
      <Flex
        direction="column"
        width={{
          base: "100%",
          lg: `${panelWidth}px`,
        }}
        pr={{
          base: 0,
          lg: 0,
        }}
        height={"100%"}
        display={{
          base: isInDrawer ? 'flex' : 'none',
          lg: 'flex',
        }}
        flexShrink={0}
        flexGrow={0}
        position="relative"
      >
        <VStack width="100%" align="start" justify="stretch" px={3} pt={4} pb={2}>
          <Link to="/">
            <Image src={skin.logo} width="70px" />
          </Link>
          <Button
            size="md"
            colorScheme="primary"
            color="black"
            variant="solid"
            mt={5}
            isDisabled={false}
            isLoading={false}
            height="40px"
            lineHeight="40px"
            as={Link}
            to={`/`}
            width="100%"
          >
            New Chat
          </Button>
        </VStack>
        <VStack spacing={1} align="stretch" height="100%" overflowY="scroll" overflowX="hidden" mt={0}>
          {chatsState.loadingList && !chatsState.initialListLoaded &&
            (
              <>
                <Skeleton height='35px' mx={3} />
                <Skeleton height='35px' mx={3} />
                <Skeleton height='35px' mx={3} />
                <Skeleton height='35px' mx={3} />
                <Skeleton height='35px' mx={3} />
                <Skeleton height='35px' mx={3} />
                <Skeleton height='35px' mx={3} />
                <Skeleton height='35px' mx={3} />
                <Skeleton height='35px' mx={3} />
                <Skeleton height='35px' mx={3} />
              </>
            )
          }
          <>
            {
              chatsState.threads.map((thread, index) => (
                <ChatButton
                  key={index}
                  threadId={thread.id}
                  description={thread.description}
                  isSelected={thread.id === params.threadId}
                  inDrawer={isInDrawer}
                  onDelete={onDeleteChat}
                  onEdit={onEditChat}
                  onShare={onShareChat}
                />
              ))
            }

            <Flex ref={sentinelRef} height="20px" width="100%" flexShrink={0} align="center" justify="center" pt={6} pb={10}>
              {
                chatsState.loadingList && chatsState.threads.length > 0 && (
                  <Spinner />
                )
              }
            </Flex>


          </>

        </VStack>


        <AccountWidget isInDrawer={isInDrawer} />
        <Box
          position="absolute"
          top="0px"
          right="0px"
          bottom="0px"
          width="5px"
          cursor="ew-resize"
          onMouseDown={handleMouseDown}
        />
      </Flex>
      <Modal
        isOpen={renameDialogOpen}
        onClose={closeRenameDialog}
        isCentered
        initialFocusRef={renameInitialRef}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rename Chat</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Chat name</FormLabel>
              <Input ref={renameInitialRef} value={chatName} onChange={(e) => setChatName(e.target.value)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={renameChat} isDisabled={!chatName}>
              Save
            </Button>
            <Button onClick={closeRenameDialog}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ShareDialog
        isOpen={shareDialogThread !== null}
        onClose={closeShareDialog}
        threadId={shareDialogThread || ''}
      />

    </>
  );
};

export default LeftPanel;



interface ChatButtonProps {
  threadId: string;
  description: string;
  isSelected: boolean;
  inDrawer: boolean;
  onDelete: (threadId: string) => void;
  onEdit: (threadId: string) => void;
  onShare: (threadId: string) => void;
}

const ChatButton: React.FC<ChatButtonProps> = ({ threadId, description, isSelected, inDrawer, onEdit, onDelete, onShare }) => {
  const overflowColornoSelected = useColorModeValue('gray.50', 'gray.900');
  const overflowColorSelected = useColorModeValue('gray.100', 'gray.700');

  const overflowColorInDrawernotSelected = useColorModeValue('white', 'gray.700');
  const overflowColorInDrawerSelected = useColorModeValue('white', 'gray.700');


  const overflowColor = isSelected ? (inDrawer ? overflowColorInDrawerSelected : overflowColorSelected) : (inDrawer ? overflowColorInDrawernotSelected : overflowColornoSelected);



  return (
    <Box position="relative" role="group">
      <Button
        variant={isSelected ? "solid" : "ghost"}
        justifyContent="start"
        isTruncated
        display="block"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="clip"
        textAlign="left"
        m={0}
        as={Link}
        to={`/chat/${threadId}`}
        height="40px"
        lineHeight="40px"
        borderRadius={1}
        maxWidth="100%"

      >
        {description}</Button>
      <Box
        position="absolute"
        top={0}
        right={0}
        width="100px"
        height="100%"
        pointerEvents="none"
        bgGradient={`linear(to-r, transparent, ${overflowColor})`}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<MdMoreHoriz />}
          variant='ghost'
          // bgColor="transparent"
          size="sm"
          position="absolute"
          right={1}
          top="50%"
          transform="translateY(-50%)"
          borderRadius={90}
          display={isSelected ? 'flex' : 'none'}
          bgColor={useColorModeValue('gray.100', 'gray.700')}
          _hover={{
            bgColor: useColorModeValue('gray.200', 'gray.800')
          }}
          _active={{
            bgColor: useColorModeValue('gray.200', 'gray.800')
          }}
        />
        <MenuList>
          <MenuItem icon={<IoIosSend />} onClick={() => {
            onShare(threadId);
          }}>
            Share
          </MenuItem>
          <MenuItem icon={<MdEdit />} onClick={() => {
            onEdit(threadId);
          }}>
            Rename
          </MenuItem>
          <MenuItem icon={<MdDelete />} onClick={() => {
            onDelete(threadId);
          }}>
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}

interface ShareDialogProps {
  isOpen: boolean;
  onClose: () => void;
  threadId?: string;
}

const ShareDialog: React.FC<ShareDialogProps> = ({ isOpen, onClose, threadId }) => {
  const [ shareUrl, setShareUrl ] = useState<string | null>(null);
  const chats = useChats();
  const toast = useToast();


  const [ shareLinkPending, setShareLinkPending ] = useState(false);

  const url = shareUrl ? shareUrl : `${window.location.origin}/...`;

  const { onCopy } = useClipboard(url);

  useEffect(() => {
    setShareUrl(null);
  }, [ isOpen ]);

  const createShareLink = async () => {
    setShareLinkPending(true);
    const id = await chats.createShareableLink(threadId || '');
    setShareLinkPending(false);
    if (!id) {
      toast({
        position: 'top-right',
        render: (props) => {
          return (
            <ToastMessage
              message="Error creating share link."
              status="error"
              isCLosable={props.isClosable}
              onClose={props.onClose}
            />
          );
        },
        duration: 1000,
        isClosable: true,
      });
    }
    else {
      setShareUrl(`${window.location.origin}/share/${id}`);
    }
  }

  const copyLink = () => {
    onCopy();
    toast({
      position: 'top-right',
      render: (props) => {
        return (
          <ToastMessage
            message="Share link copied to clipboard."
            status="success"
            isCLosable={props.isClosable}
            onClose={props.onClose}
          />
        );
      },
      duration: 1000,
      isClosable: true,
    });
  }



  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share public link to this chat thread</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text fontSize={14}>Your name and any messages you add after sharing will stay private.</Text>
          <FormControl>
            <Input value={url} isReadOnly={true} disabled={!shareUrl} sx={{ mt: 5 }} />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          {
            !shareUrl && (
              <Button
                colorScheme="primary"
                color="black"
                leftIcon={<IoIosAddCircle />}
                isLoading={shareLinkPending}
                onClick={createShareLink}
              >
                Create Link
              </Button>
            )
          }

          {
            shareUrl && (
              <>
                <Icon as={FaCheck} />
                <Text sx={{ml: 1}}>Share link created</Text>
                <Button
                  sx={{ ml: 2 }}
                  colorScheme="primary"
                  color="black"
                  onClick={copyLink}
                >
                  Copy Link
                </Button>
              </>
            )
          }
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}