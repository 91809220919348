import React from "react";
import { Message } from "../../../gen-ts/ai/assistants/v0/assistant_pb";
import { Box, HStack, useColorModeValue, VStack } from "@chakra-ui/react";
import MessageFiles from "./MessageFiles";
import MessageContent from "./MessageContent";

const UserMessage: React.FC<{ message: Message }> = ({ message }) => {
  const bgColor = useColorModeValue('gray.200', 'gray.700');
  return (
    <>
      <VStack width="100%" spacing="3px">
        <MessageFiles message={message} />
        <HStack
          justify="end"
          bgColor={bgColor}
          borderRadius="20px"
          alignSelf="end"
          px={4}
          py={2}
        >
          <Box
            maxWidth={600}
          >
            <MessageContent message={message} />
          </Box>
        </HStack>
      </VStack>
    </>
  );
}

export default UserMessage;