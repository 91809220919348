import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React, { PropsWithChildren, createContext, useContext, useState } from "react";
import AssistantsGrid from "./AssistantsGrid";



interface OpenArgs {
  onSelect: (assistantId: string) => void;
  title?: string;
  unselectableIds?: string[];
}

interface IAssistantsDialogContext {
  onSelected?: (assistantId: string) => void
  close: () => void;
  open: (args: OpenArgs) => void;
  isOpen: boolean;
  title?: string;
  unselectableIds?: string[];
}

const AssistantsDialogContext = createContext<IAssistantsDialogContext>({
  close: () => { },
  open: () => { },
  isOpen: false,

})



export const AssistantsDialog: React.FC = () => {
  const state = useContext(AssistantsDialogContext);


  return (
    <Modal
      isOpen={state.isOpen}
      onClose={state.close}
      size={{
        base: "full",
      }}
      autoFocus
    >
      <ModalOverlay />
      <ModalContent maxWidth="1200px" maxHeight="90vh" overflow="scroll">
        <ModalHeader>{state.title ?? 'Select Assistannt'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody height="100%" overflow="scroll" width="100%">
          <AssistantsGrid
            unselectableIds={state.unselectableIds}
            onSelect={(assistantId) => {
              state.onSelected?.(assistantId);
              state.close();
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};



export const AssistantDialogProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [ state, setState ] = useState<IAssistantsDialogContext>({
    isOpen: false,
    close: () => {
      setState({ ...state, isOpen: false });
    },
    open: (args) => {
      setState({
        ...state,
        unselectableIds: args.unselectableIds,
        isOpen: true,
        onSelected: args.onSelect,
        title: args.title,
      });
    },
  });


  const context: IAssistantsDialogContext = {
    isOpen: state.isOpen,
    close: state.close,
    open: state.open,
    onSelected: state.onSelected,
    title: state.title,
    unselectableIds: state.unselectableIds,
  }


  return (
    <AssistantsDialogContext.Provider value={context}>
      {children}
    </AssistantsDialogContext.Provider>
  );
}

export const useAssistantsDialog = () => {

  const context = useContext(AssistantsDialogContext);

  return {
    isOpen: context.isOpen,
    open: context.open,
    close: context.close,
  };

}