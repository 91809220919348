import {  useToast } from "@chakra-ui/react";
import ToastMessage from "../components/ToastMessage";

export const useAppToast = () => {
  const toast = useToast();

  return (args: {message: string, variant: "success" | "error", duration?: number}) => {
    
    toast({
      position: 'top-right',
      render: (props) => {
        return (
          <ToastMessage
            message={args.message}
            status={args.variant}
            isCLosable={props.isClosable}
            onClose={props.onClose}
          />
        );
      },
      duration: args.duration ?? 1000,
      isClosable: true,
    });

  };
}