import { Message } from "../../../../gen-ts/ai/assistants/v0/assistant_pb";
import AskScendRenderer from "./AskAscend";



export interface CustomRendererProps {
  message: Message;
  onFeedback: (runId: string) => void;
  onShowSources: (runId: string, filters?: string[]) => void;
}
export const customRenderers: Record<string, React.FC<CustomRendererProps>> = {
  'ASSISTANT_4635ZEG88M09MBVJW2X4N4Q4R1': AskScendRenderer,
}